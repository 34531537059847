import { useJitsu } from '@jitsu/react';
import { EventPayload } from '@jitsu/sdk-js';
import Cookies from 'js-cookie';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const ANONYMOUS_ID_COOKIE_KEY = '__eventn_id';

async function hash(message: string) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export const useTracking = () => {
  const { track, id } = useJitsu();
  return {
    trackEvent: (eventName: string, eventData?: EventPayload) => {
      track(eventName, eventData);
      window.dataLayer?.push({
        event: eventName,
        ...eventData
      });
    },
    getAnonymousId: () => {
      return Cookies.get(ANONYMOUS_ID_COOKIE_KEY);
    },
    login: (userId: string) => {
      id({ id: userId });
    },
    logout: () => {
      id({});
    },
    hashContactInfo: async (email?: string, phone?: string) => {
      try {
        const phoneDigits = phone
          ?.replace(/\D/g, '')
          .replace(/^(\d{9})$/, '36$1')
          .replace(/^06/, '36');
        return {
          sha256_email_address: email?.trim() ? await hash(email.trim().toLowerCase()) : undefined,
          sha256_phone_number:
            phoneDigits?.length === 11 || phoneDigits?.length === 15 ? await hash(`+${phoneDigits}`) : undefined
        };
      } catch (e: unknown) {
        return {};
      }
    }
  };
};
