import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Alert, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../components/header/images/main-header-background.webp';
import { BookingDepartureCallbackDialog } from '../booking-departure-callback-dialog/booking-departure-callback-dialog';

export const CallbackBanner: React.FC<{}> = (props) => {
  const { t } = useTranslation('common');
  const isCallbackBannerEnabled = useFeatureIsOn('callback-banner');
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [callbackSuccessful, setCallbackSuccessful] = useState(false);

  if (!isCallbackBannerEnabled) {
    return <></>;
  }

  const handleCallbackRequest = () => {
    setCallbackSuccessful(true);
  };

  return (
    <Box
      p={2}
      sx={{ borderRadius: '20px', backgroundImage: `url(${bannerImage})`, marginTop: { xs: '10px', md: '25px' } }}
    >
      <Stack direction={isLayoutSmall ? 'column' : 'row'} alignItems="center" columnGap={2}>
        <Typography
          variant="h1"
          sx={{ textAlign: isLayoutSmall ? 'center !important' : 'left !important' }}
          color="#fff"
        >
          {t('search.banner.callback.title')}
        </Typography>
        {!callbackSuccessful && (
          <BookingDepartureCallbackDialog
            handleChange={handleCallbackRequest}
            color={theme.palette.info.main}
            showMessageBox
          />
        )}
        {callbackSuccessful && (
          <Alert severity="success" variant="filled">
            <Typography variant="body1" textAlign="left" fontSize={14} color="#fff">
              {t('booking.departure.callback-dialog.success-text')}
            </Typography>
          </Alert>
        )}
      </Stack>
    </Box>
  );
};
