import { Box, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';

export const CompanyDetailsPageAE: React.FC<{}> = (props) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Box>
        <Stack spacing={8}>
          <SectionBox boxParams={{ style: { padding: '0 100px' } }}>
            <SectionTitle title={t('company-details.title')} />
            <Box marginTop={3} marginBottom={3}>
              CLICKnCRUISE Ltd.
              <br />
              Address: 2. B. Ép Rét köz, Ikrény, 9141 Hungary
              <br />
              Email: {__CONFIG__.support.email}
              <br />
              {__CONFIG__.support.phone && <>Telefon: {__CONFIG__.support.phone}</>}
              <br />
              Company Reg No: 08-09-033950
              <br />
              EU VAT number: HU27547111
              <br />
              Hosting: Amazon Web Services
              <br />
            </Box>
          </SectionBox>
        </Stack>
      </Box>
    </>
  );
};
CompanyDetailsPageAE.displayName = 'CompanyDetailsPageAE';
