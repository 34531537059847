import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../section-title/section-title';
import { MAILERLITE_HTML_AE, MAILERLITE_HTML_HU, MAILERLITE_HTML_RO, MAILERLITE_HTML_UK } from './mailerlite-html';
import './subscription-box.scss';

export const SubscriptionBox: React.FC<{}> = (props) => {
  // Translation
  const { t } = useTranslation('common');

  useEffect(() => {
    // Mailer Lite translation...
    setTimeout(() => {
      const form = document.getElementById('subscription-form');
      if (form) {
        form
          .querySelectorAll('input[type=email]')
          .forEach((e) => ((e as HTMLInputElement).placeholder = t('newsletter.email-placeholder')));
        form
          .querySelectorAll('button[type=submit]')
          .forEach((e) => (e.innerHTML = t('newsletter.subscribe-button-label')));
      }
    }, 1000);

    const scripts = document.getElementById('subscription-form')?.getElementsByTagName('script');
    if (scripts) {
      for (const script of scripts) {
        if (script.src) {
          const newScript = document.createElement('script');
          newScript.src = script.src;
          newScript.async = script.async;
          document.body.appendChild(newScript);
        } else {
          window.eval(script.innerHTML);
        }
      }
    }
  });

  const MAILERLITE_HTML = {
    hu: MAILERLITE_HTML_HU,
    ro: MAILERLITE_HTML_RO,
    uk: MAILERLITE_HTML_UK,
    ae: MAILERLITE_HTML_AE
  }[__CONFIG__.market];

  return (
    <>
      <Box className="subscription-box">
        <Stack spacing={0}>
          <SectionTitle title={t('newsletter.title')} subTitle={t('newsletter.subtitle')} textAlign="center" />
          <Grid container spacing={0} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <div id="subscription-form">
                <div dangerouslySetInnerHTML={{ __html: MAILERLITE_HTML }}></div>
              </div>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};
