import { Box, Grid, Stack } from '@mui/material';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';
import { SubscriptionBox } from '../components/subscription-box/subscription-box';

export const PrivacyPolicyPageHU: React.FC<{}> = (props) => {
  return (
    <>
      <Box>
        <Stack spacing={8}>
          <Box padding={{ xs: '0 20px', md: '0 100px' }}>
            <SectionTitle title={'CLICKnCRUISE Korlátolt Felelősségű Társaság Adatkezelési tájékoztató'} />
            <SectionBox boxParams={{ style: { marginTop: '40px' } }}>
              <p>
                CLICKnCRUISE Kft (továbbiakban: Szolgáltató vagy Adatkezelő) kiemelt figyelmet fordít arra, hogy
                adatkezelése során ügyfelei az önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII.
                törvény (a továbbiakban: Info.tv.) és az egyéb hatályos adatkezeléssel kapcsolatos jogszabályoknak,
                különösen a 2016/679 uniós rendelet (a továbbiakban: GDPR) rendelkezéseinek &nbsp;megfelelően járjon el.
              </p>
              <h3>Adatkezelő megnevezése</h3>
              <p>
                Név: CLICKnCRUISE Kft
                <br />
                Székhely: 9141, Ikrény Rét köz 2. B. Ép.
                <br />
                Adószám: 27547111-2-08
                <br />
                Cégjegyzékszám: 08-09-033950
                <br />
                Nyilvántartási szám: U-001970 <br />
                Email cím: ahoy@clickncruise.hu
                <br />
              </p>
              <h3>Adatfeldolgozó </h3>
              <p>
                Amazon Web Services (AWS) <br />
                Cím: 1200 12th Ave S, Ste 1200, Seattle, WA 98144, United States <br />
                Web: https://aws.amazon.com,
                <br />
                &Aacute;SZF: https://aws.amazon.com/privacy/?nc1=f_pr
                <br />
                Adatfeldolgozó feladata: weboldal üzemeltetése, tárhelyszolgáltató <br />
              </p>
              <h3>Az adatkezelés jogalapja, meghatározása</h3>

              <p>
                A honlap révén megvalósuló adatkezelésekre az Info.tv. 5. &sect; (1) bekezdés a) pontja alapján, a
                felhasználók <strong>önkéntes hozzájárulásával</strong>&nbsp;kerül sor. A hozzájárulást a felhasználó a
                honlap használatával, valamint &ndash; a hírlevélre történő feliratkozás során &ndash; személyes adatai
                önkéntes megadásával adja meg.
              </p>

              <p>
                A honlapon találhatóak olyan linkek, amelyek más honlapokra mutatnak. Az ilyen külső honlapok
                adatkezelésére vonatkozóan az adott honlap adatkezelési nyilatkozatában foglaltak irányadók.
              </p>

              <h3>A kezelt adatok köre, az adatkezelés célja és időtartama</h3>

              <p>
                Az adatkezelés időtartama: az adatok megadásától, illetve a szolgáltatás igénybevételétől
                (szerződéskötéstől) számított 180 naptári nap.
              </p>

              <p>
                Adatkezelő tájékoztatja a honlap Látogatóit, Felhasználóit, hogy a honlap cookie-kat használ a hatékony
                működés érdekében.
              </p>

              <p>
                Ez a honlap a Google Analytics alkalmazást használja, amely a Google Inc. (&bdquo;Google&rdquo;)
                webelemző szolgáltatása. A Google Analytics úgynevezett &bdquo;cookie-kat&rdquo;, szövegfájlokat
                használ, amelyeket a számítógépére mentenek, így elősegítik Felhasználó által látogatott weblap
                használatának elemzését.
              </p>
              <p>
                A Felhasználó által használt weboldallal kapcsolatos cookie-kkal létrehozott információk rendszerint a
                Google egyik USA-beli szerverére kerülnek és tárolódnak. Az IP-anonimizálás weboldali aktiválásával a
                Google a Felhasználó IP-címét az Európai Unió tagállamain belül vagy az Európai Gazdasági Térségről
                szóló megállapodásban részes más államokban előzőleg megrövidíti.
              </p>
              <p>
                A teljes IP-címnek a Google USA-ban lévő szerverére történő továbbítására és ottani lerövidítésére csak
                kivételes esetekben kerül sor. Eme weboldal üzemeltetőjének megbízásából a Google ezeket az
                információkat arra fogja használni, hogy kiértékelje, hogyan használta a Felhasználó a honlapot,
                továbbá, hogy a weboldal üzemeltetőjének a honlap aktivitásával összefüggő jelentéseket készítsen,
                valamint, hogy a weboldal- és az internethasználattal kapcsolatos további szolgáltatásokat teljesítsen.
              </p>
              <p>
                A Google Analytics keretein belül a Felhasználó böngészője által továbbított IP-címet nem vezeti össze a
                Google más adataival. A cookie-k tárolását a Felhasználó a böngészőjének megfelelő beállításával
                megakadályozhatja, azonban felhívjuk figyelmét, hogy ebben az esetben előfordulhat, hogy ennek a
                honlapnak nem minden funkciója lesz teljes körűen használható. Megakadályozhatja továbbá, hogy a Google
                gyűjtse és feldolgozza a cookie-k általi, a Felhasználó weboldalhasználattal kapcsolatos adatait
                (beleértve az IP-címet is), ha letölti és telepíti a következő linken elérhető böngésző bővítményt:
                <a href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Dhu&amp;sa=D&amp;source=editors&amp;ust=1654877844884260&amp;usg=AOvVaw3Bqie031iH1ebskO5xNpJM">
                  https://tools.google.com/dlpage/gaoptout?hl=hu
                </a>
              </p>

              <p>HotJar:</p>
              <p>
                A honlap optimalizációját a Hotjar sütijeinek segítségével végezzük, amelyek az egérmozgásról,
                kattintási adatokról és űrlapállapotokról gyűjtenek adatokat. Az adatokat a hotjar.com tárolja. Az
                adatgyűjtés során minden űrlapadat (*-karakterrel) maszkolásra kerül, valós adat rögzítése és tárolása
                nem történik. A HotJar adatvédelmi irányelvei itt olvashatók:
                <a href="https://www.hotjar.com/legal/policies/privacy/">
                  https://www.hotjar.com/legal/policies/privacy/
                </a>
                .
              </p>

              <p>
                A honlap használatával a honlapra látogatók engedélyezik az Adatkezelő részére, a következő cookiek
                használatát:
              </p>
              <table>
                <tr>
                  <td>
                    <p>Típus</p>
                  </td>
                  <td>
                    <p>Hozzájárulás</p>
                  </td>
                  <td>
                    <p>Leírás</p>
                  </td>
                  <td>
                    <p>Cél</p>
                  </td>
                  <td>
                    <p>Érvényesség</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>rendszer általános sütik</p>
                  </td>
                  <td>
                    <p>nem igényel</p>
                  </td>
                  <td>
                    <p>a webes alkalmazás működéséért felelős alap süti </p>
                  </td>
                  <td>
                    <p>honlap működésének biztosítása</p>
                  </td>
                  <td>
                    <p>böngésző bezárásáig</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>rendszer általános sütik</p>
                  </td>
                  <td>
                    <p>igényel</p>
                  </td>
                  <td>
                    <p>
                      a webes alkalmazás betöltését követően további funkciókhoz való hozzáféréséért szükséges
                      hozzájárulni a sütikhez
                    </p>
                  </td>
                  <td>
                    <p>Cookie használat engedélyezése és a 18 év feletti személy hozzájárulása</p>
                  </td>
                  <td>
                    <p>365 nap</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>rendszer funkció sütik</p>
                  </td>
                  <td>
                    <p>nem igényel</p>
                  </td>
                  <td>
                    <p>a könnyebb működéshez és a személyre szabáshoz szükséges sütik </p>
                  </td>
                  <td>
                    <p>
                      A rendszer teljes funkcionalitását tudja elérni vele a felhasználó, ezek nélkül számos dolog nem
                      működik
                    </p>
                  </td>
                  <td>
                    <p>böngésző bezárásáig</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>rendszer nyomkövető sütik</p>
                  </td>
                  <td>
                    <p>igényel</p>
                  </td>
                  <td>
                    <p>méréshez szükséges illetve a felhasználói élmény kialakításához szükséges</p>
                  </td>
                  <td>
                    <p>statisztika készítése</p>
                  </td>
                  <td>
                    <p>365 nap </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>nyomkövető süti </p>
                    <p>(harmadik féltől származó)</p>
                  </td>
                  <td>
                    <p>nem igényel</p>
                  </td>
                  <td>
                    <p>
                      új látogatók azonosítására és a webes nyomkövetésre alkalmas (van rendszerbe integrált és van amit
                      egyénileg is beállíthat) (Google, Facebook, Twitter, AddThis)
                    </p>
                  </td>
                  <td>
                    <p>pontos cél, pl. &nbsp;</p>
                    <p>google Adwords konverziókövetés cookieval a Google Ads hirdetések hatékonyságát mérjük</p>
                  </td>
                  <td>
                    <p>pontos lejárat</p>
                    <p>Google Ads: 30nap</p>
                  </td>
                </tr>
              </table>

              <h4>Cookiek letiltása</h4>

              <p>
                Az Adatkezelő tájékoztatja a honlap látogatóit, felhasználóit, hogy lehetőségük van a cookiek
                használatának tiltására a saját felhasználói eszközeik beállításaiban. Ez a beállítás általában az
                eszköz böngészőjének beállításai között az &rdquo; Adatvédelem és Biztonság&rdquo; menü alatt érhető el.
              </p>

              <h4>Facebook Pixel alkalmazása</h4>

              <p>
                Honlapunkon alkalmazzuk a&nbsp;Facebook Pixelt, amely Facebook által üzemeltett eszköz a webhelyek
                gazdasági üzemeltetésére, optimalizálására és elemzésére. A Facebook Pixel révén a Facebook
                célcsoportként kezelheti honlapunk látogatóit a Facebook-hirdetések megjelenítéséhez.
              </p>

              <p>
                Ennek megfelelően azért alkalmazzuk a Facebook Pixelt, hogy az általunk beágyazott Facebook-hirdetések
                csak azoknál a Facebook felhasználóknál jelenjenek meg, akik érdeklődést mutattak az ajánlataink iránt
                is. Vagyis a Facebook Pixellel biztosítani szeretnénk, hogy a Facebook-hirdetések illeszkedjenek a
                felhasználók potenciális érdeklődéséhez, és ne zavaró tényezők legyenek. A Facebook Pixel segítségével
                emellett statisztikai és piackutatási célból elemezhetjük a Facebookon megjelenő hirdetéseink
                eredményességét, mivel megtudhatjuk, hogy a felhasználók a Facebook-hirdetésre kattintással jutottak-e
                el a webhelyünkre (úgynevezett&nbsp;&quot;konverzió&quot;&nbsp;vagy&nbsp;&quot;felhasználói
                interakció&quot;). Ez esetben az adatkezelés a GDPR 6. cikke, 1. bekezdése, 1. mondata a) pontjára
                alapszik.
              </p>
              <p>
                A Facebook a webhelyünk megnyitásakor közvetlenül beágyazza a Facebook Pixelt, és egy sütit, vagyis egy
                kisméretű fájlt helyezhet el az eszközén. Ha később bejelentkezik a Facebookra, vagy bejelentkezett
                állapotban ellátogat a Facebook oldalaira, akkor a profiljában a webhelyünkön tett látogatását
                regisztrálják. Az Önről megszerzett adatok számunkra anonimak, vagyis ennek alapján nem kapunk a
                felhasználók személyazonosságára vonatkozó adatokat. Az adatokat ugyanakkor a Facebook tárolja és
                dolgozza fel, így összekapcsolhatók az adott felhasználói profillal. Ez azt jelenti, hogy a feldolgozott
                adatok alapján a felhasználókról profilokat készíthetnek. A Facebook a saját adatkezelési szabályzata
                szerint kezeli az adatokat. Így tehát a Facebook Pixel működésével és általában a Facebook-hirdetések
                megjelenítésével kapcsolatos további információt a Facebook adatkezelési szabályzatában talál a
                következő címen:&nbsp;
                <a href="https://www.google.com/url?q=https://www.facebook.com/policy.php&amp;sa=D&amp;source=editors&amp;ust=1654877844894801&amp;usg=AOvVaw3nYJgLM8wYSxH2yj469dfp">
                  https://www.facebook.com/policy.php
                </a>
                .
              </p>

              <p>
                A Szolgáltató internetes oldalai bármilyen adat megadása nélkül látogathatóak. Vannak azonban olyan
                jellegű szolgáltatások, amelyekhez szükségszerűen hozzátartozik az adatszolgáltatás, az alábbiakban
                részletezzük milyen esemény során milyen adatszolgáltatásra kerül sor.
              </p>
              <table>
                <tr>
                  <td>
                    <p>Tevékenység megnevezése és az adatkezelés célja</p>
                  </td>
                  <td>
                    <p>Jogalap</p>
                  </td>
                  <td>
                    <p>Kezelt adatok</p>
                  </td>
                  <td>
                    <p>Időtartam</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Hozzájáruló nyilatkozat kozmetikai kezeléshez</p>
                  </td>
                  <td>
                    <p>hozzájárulás</p>
                    <p>GDPR 6. cikk, 1. bekezdés, a) pontja</p>
                  </td>
                  <td>
                    <p>
                      természetes személy családi és utóneve
                      <br />
                      lakcíme
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>5 évig</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Honlap látogatása</p>
                    <p>Cél a honlap rendeltetésszerű és színvonalas működésének biztosítása, </p>
                    <p>a szolgáltatásaink minőségének ellenőrzése és javítása, </p>
                    <p>a rosszindulatú, honlapunkat támadó látogatók beazonosítás,</p>
                    <p>a látogatottság mérésére, </p>
                    <p>statisztikai célok</p>
                  </td>
                  <td>
                    <p>társaságunk jogos érdeke;</p>
                    <p>GDPR 6. cikk, 1. bekezdés, f) pontja</p>
                  </td>
                  <td>
                    <p>IP cím </p>
                    <p>a látogatás időpontja </p>
                    <p>a meglátogatott aloldalak adatai,</p>
                    <p>az Ön által használt operációs rendszer és böngésző típusa</p>
                  </td>
                  <td>
                    <p>nem releváns</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Űrlap beküldése
                      <br />
                      kapcsolatfelvétel az ügyféllel
                    </p>
                  </td>
                  <td>
                    <p>hozzájárulás</p>
                    <p>GDPR 6. cikk, 1. bekezdés, a) pontja</p>
                  </td>
                  <td>
                    <p>
                      pl: természetes személy családi és utóneve
                      <br />
                      email cím
                      <br />
                      IP cím
                    </p>
                  </td>
                  <td>
                    <p>180 nap</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Hírlevél feliratkozás
                      <br />a szolgáltatáshoz hozzájárulóknak a Társaság személyre szabott marketing ajánlatokat készít
                      és küld, őket üzletszerzési célból megkeresi, illetve a Társaság által forgalmazott termékekről és
                      szolgáltatásokról tájékoztatókat küld.
                    </p>
                  </td>
                  <td>
                    <p>hozzájárulás</p>
                    <p>GDPR 6. cikk, 1. bekezdés, a) pontja</p>
                  </td>
                  <td>
                    <p>
                      név
                      <br />
                      email cím
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>hírlevélről történő leiratkozásig</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Hozzászólás cikkhez</p>
                  </td>
                  <td>
                    <p>hozzájárulás</p>
                    <p>GDPR 6. cikk, 1. bekezdés, a) pontja</p>
                  </td>
                  <td>
                    <p>
                      név
                      <br />
                      email cím
                      <br />
                      dátum
                    </p>
                  </td>
                  <td>
                    <p>visszavonásig</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Hozzászólás adatlaphoz, vendégkönyvhöz</p>
                  </td>
                  <td>
                    <p>hozzájárulás</p>
                    <p>GDPR 6. cikk, 1. bekezdés, a) pontja</p>
                  </td>
                  <td>
                    <p>
                      név
                      <br />
                      email cím
                      <br />
                      IP cím
                      <br />
                      dátum
                    </p>
                  </td>
                  <td>
                    <p>visszavonásig</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Direkt marketing szolgáltatás</p>
                    <p>az Ön vásárlói szokásainak elemzése alapján </p>
                    <p>személyre szabott ajánlatokat készítünk és küldünk, </p>
                    <p>üzletszerzési célból Önt megkeressük,</p>
                    <p>az általunk forgalmazott termékekről és szolgáltatásokról tájékoztatókat küldünk.</p>
                  </td>
                  <td>
                    <p>hozzájárulás</p>
                    <p>GDPR 6. cikk, 1. bekezdés, a) pontja</p>
                  </td>
                  <td>
                    <p>teljes név,</p>
                    <p>e-mail cím, </p>
                    <p>telefonszám (nem kötelező)</p>
                    <p>egyéb, nem kötelezően megadandó adatok, pl. érdeklődési kör, lakhely, stb</p>
                  </td>
                  <td>
                    <p>direkt marketing szolgáltatásról leiratkozásig</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>&Uuml;gyintézés, panasz</p>
                    <ul>
                      <li>észrevételre, panaszra válaszadás</li>
                      <li></li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>jogi kötelezettség</li>
                      <li>GDPR 6. cikk, 1. bekezdés, c) pontja</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>teljes név</li>
                      <li>e-mail cím</li>
                      <li>telefonszám</li>
                      <li>levelezési cím</li>
                      <li>egyéb személyes üzenet</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>5 évig</li>
                    </ul>
                  </td>
                </tr>
              </table>

              <p>
                Az adatkezeléssel kapcsolatos kérdéseivel Ön az ahoy@clickncruise.hu e-mail, illetve postacímünkön
                kérhet további tájékoztatást, válaszunkat indokolatlan késedelem nélkül, legfeljebb 28 napon belül
                megküldjük Önnek az Ön által megadott elérhetőségre.
              </p>

              <p>
                Fenti esetekben a legszükségesebb adatok kizárólag az online ügylet lebonyolításához, a számlázáshoz,
                illetve a szolgáltatás nyújtása érdekében kerülnek rögzítésre addig az időpontig, ameddig ez feltétlenül
                szükséges; beleértve ebbe az időtartamba a reklamáció és a jótállás, szavatosság esetkörét, tehát a
                szerződés feltételeinek későbbi bizonyítása érdekében történő tárolás esetét is. Ezt követően a megadott
                adatok a Szerződés megszűnését követő 180. naptári nap törlésre kerülnek.
              </p>

              <p>
                A regisztráció illetve marketing szolgáltatásaink igénybevétele kapcsán megadott személyes adatokat nem
                kapcsolhatjuk össze és a látogatóink beazonosítása alapvetően nem célunk.
              </p>

              <p>
                A személyes adatokat Ön önkéntesen bocsátja rendelkezésünkre a regisztráció, illetve a Szolgáltatóval
                történő kapcsolattartása során, éppen ezért kérjük, hogy adatai közlésekor fokozatosan ügyeljen azok
                valódiságára, helyességére és pontosságára, mert ezekért Ön felelős. A helytelen, pontatlan vagy hiányos
                adat akadálya lehet a szolgáltatásaink igénybevételének.
              </p>

              <p>
                Amennyiben Ön nem a saját, hanem más személy személyes adatait adja meg, úgy vélelmezzük, hogy Ön az
                ehhez szükséges felhatalmazással rendelkezik.
              </p>

              <p>Ön az adatkezeléshez adott hozzájárulását bármikor ingyenesen visszavonhatja</p>

              <p>a regisztráció törlésével,</p>
              <p>az adatkezeléshez hozzájárulás visszavonásával, illetve</p>
              <p>
                a regisztráció során feltétlen kitöltendő bármely adat kezeléséhez vagy felhasználásához való
                hozzájárulás visszavonásával vagy zárolásának kérésével.
              </p>

              <p>
                A hozzájárulás visszavonásának regisztrálását &ndash; technikai okokból &ndash; 24 órás határidővel
                vállaljuk, azonban felhívjuk a figyelmét arra, hogy jogi kötelezettségünk teljesítése vagy jogos
                érdekeink érvényesítése céljából bizonyos adatokat a hozzájárulás visszavonása után is kezelhetünk.
              </p>

              <p>
                Megtévesztő személyes adat használata esetén, illetve ha valamelyik látogatónk bűncselekményt követ el
                vagy informatikai rendszerünket támadja, az adott látogató regisztrációjának megszüntetésével
                egyidejűleg adatait haladéktalanul töröljük, illetve &ndash; szükség esetén &ndash; megőrizzük azokat a
                polgári jogi felelősség megállapításának vagy büntető eljárás lefolytatásának időtartama alatt.
              </p>

              <h3>Egyéb adatkezelési kérdések</h3>

              <p>
                Az Ön adatait csak jogszabályban meghatározott keretek között továbbíthatjuk, adatfeldolgozóink esetében
                pedig szerződéses feltételek kikötésével biztosítjuk, hogy ne használhassák az Ön hozzájárulásával
                ellentétes célokra az Ön személyes adatait.
              </p>

              <p>Külföldre nem továbbítunk adatokat. </p>
              <p>
                A bíróság, az ügyészség és más hatóságok (pl. rendőrség, adóhivatal, Nemzeti Adatvédelmi és
                Információszabadság Hatóság) tájékoztatás adása, adatok közlése vagy iratok rendelkezésre bocsátása
                miatt megkereshetnek meg bennünket. Ezekben az esetekben adatszolgáltatási kötelezettségünket
                teljesítenünk kell, de csak a megkeresés céljának megvalósításához elengedhetetlenül szükséges
                mértékben.
              </p>

              <p>
                A Szolgáltató adatkezelésében és/vagy adatfeldolgozásában részt vevő közreműködői és munkavállalói előre
                meghatározott mértékben &ndash; titoktartási kötelezettség terhe mellett &ndash; jogosultak az Ön
                személyes adatait megismerni.
              </p>

              <p>
                Az Ön személyes adatait megfelelő technikai és egyéb intézkedésekkel védjük, valamint biztosítjuk az
                adatok biztonságát, rendelkezésre állását, továbbá óvjuk azokat a jogosulatlan hozzáféréstől,
                megváltoztatástól, sérülésektől illetve nyilvánosságra hozataltól és bármilyen egyéb jogosulatlan
                felhasználástól.
              </p>

              <h3>Látogató jogai és jogorvoslati lehetőségei</h3>

              <p>
                A jogszabályi követelmények alapján Önt a következő jogok illetik meg személyes adataival kapcsolatban:
              </p>

              <ul>
                <li>
                  Az adatkezeléssel kapcsolatos felvilágosításhoz és a feldolgozott adatok másolatának megszerzéséhez
                  való jog (hozzáférési jog, a GDPR 15. cikke),
                </li>
                <li>
                  a helytelen adatok helyesbítésének vagy a hiányos adatok kiegészítésének kérésére vonatkozó jog (a
                  helyesbítéshez való jog, a GDPR 16. cikke),
                </li>
                <li>
                  a személyes adatok törlésének kérésére vonatkozó jog, amennyiben fennállnak ennek törvényi feltételei,
                  valamint a személyes adatok átadása esetén az egyéb adatkezelőknek a törlés iránti kérelemmel
                  kapcsolatban elküldött információk, amennyiben ezt a jogszabály előírja (a törléshez való jog, a GDPR
                  17. cikke),
                </li>
                <li>
                  az adatkezelés korlátozásának kérésére vonatkozó jog (az adatkezelés korlátozásához való jog, a GDPR
                  18. cikke),
                </li>
                <li>
                  a személyes adatok strukturált, széles körben használt és géppel olvasható formátumban történő
                  megszerzésének joga, valamint ezen adatok más, az adatkezelésért felelős félnek való átadásának
                  kérésére vonatkozó jog (az adathordozhatósághoz való jog, a GDPR 20. cikke),
                </li>
                <li>
                  az adatkezelés elleni tiltakozásra vonatkozó jog az adatkezelés megakadályozása érdekében, amennyiben
                  az adatkezelés az Ön hozzájárulásán, ill. a kozmetika vagy valamely harmadik fél jogos érdekein
                  alapul. Ez a jog nem érvényes abban az esetben, ha az adatkezelést olyan kényszerítő okok indokolják,
                  amelyek elsőbbséget élveznek az Ön érdekeivel szemben, vagy ha az adataira jogi követelések
                  érvényesítéséhez, gyakorlásához vagy védelméhez van szükség (a tiltakozáshoz való jog, a GDPR 21.
                  cikke),
                </li>
                <li>
                  a megosztott felelősség lényeges vonatkozásaival kapcsolatos információkhoz való jog, amelyekből
                  kiderül minden, a személyes adatok kezeléséért felelős személy szerepe és felelőssége, valamint az
                  érintettek jogainak gyakorlására szolgáló mechanizmusok és folyamatok (a GDPR 28. cikkének (2)
                  bekezdése);
                </li>
                <li>
                  a korábban adott hozzájárulás bármikor történő visszavonásának joga a hozzájáruláson alapuló
                  adatkezelés megakadályozása érdekében. A visszavonás nem befolyásolja a visszavonást megelőző,
                  hozzájáruláson alapuló adatkezelés jogszerűségét (a visszavonáshoz való jog, a GDPR 7. cikke),
                  valamint
                </li>
                <li>
                  Önnek jogában áll bíróság előtt jogorvoslattal élni, vagy az illetékes felügyeleti hatósághoz (
                  <a href="https://www.google.com/url?q=https://naih.hu/panaszuegyintezes-rendje.html&amp;sa=D&amp;source=editors&amp;ust=1654877844914080&amp;usg=AOvVaw19DdCHYeuimGM9cITvG9fK">
                    https://naih.hu/panaszuegyintezes-rendje.html
                  </a>
                  ) panaszt benyújtani, ha úgy gondolja, hogy az adatkezelés sérti a GDPR-t (a GDPR 77. cikke).
                </li>
              </ul>

              <strong>Felügyelő Hatóság: Nemzeti Adatvédelmi és Információszabadság Hatóság</strong>

              <ul>
                <li>Székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.</li>
                <li>Levelezési cím: 1530 Budapest, Pf.: 5.</li>
                <li>Telefon: +36 (1) 391-1400</li>
                <li>Fax: +36 (1) 391-1410</li>
                <li>
                  E-mail:<a href="mailto:ugyfelszolgalat@naih.hu">ugyfelszolgalat@naih.hu</a>
                </li>
                <li>Honlap:https://naih.hu/</li>
              </ul>

              <p>
                <strong>Az Ön kérelmére tájékoztatást adunk</strong>&nbsp;az Ön általunk kezelt, illetve az általunk
                &ndash; vagy a megbízott adatfeldolgozónk által &ndash; feldolgozott
              </p>

              <ul>
                <li>adatairól, </li>
                <li>azok forrásáról, </li>
                <li>az adatkezelés céljáról és jogalapjáról, </li>
                <li>időtartamáról, ha pedig ez nem lehetséges, ezen időtartam meghatározásának szempontjairól,</li>
                <li>az adatfeldolgozóink nevéről, címéről és az adatkezeléssel összefüggő tevékenységükről, </li>
                <li>
                  adatvédelmi incidensek körülményeiről, hatásairól és az elhárításukra valamint megelőzésükre tett
                  intézkedéseinkről, továbbá
                </li>
                <li>az Ön személyes adatainak továbbítása esetén az adattovábbítás jogalapjáról és címzettjéről.</li>
              </ul>

              <p>
                A kérelem benyújtásától számított legrövidebb idő alatt, 28 napon belül adjuk meg tájékoztatásunkat. A
                tájékoztatás ingyenes kivéve akkor, ha Ön a folyó évben azonos adatkörre vonatkozóan tájékoztatási
                kérelmet már nyújtott be hozzánk. Az Ön által már megfizetett költségtérítést visszatérítjük abban az
                esetben, ha az adatokat jogellenesen kezeltük vagy a tájékoztatás kérése helyesbítéshez vezetett. A
                tájékoztatást csak törvényben foglalt esetekben tagadhatjuk meg jogszabályi hely megjelölésével,
                valamint a bírósági jogorvoslat illetve a Hatósághoz fordulás lehetőségéről tájékoztatással.
              </p>

              <p>
                A személyes adatok helyesbítésről, zárolásról, megjelölésről és törlésről Önt, továbbá mindazokat
                értesítjük, akiknek korábban az adatot adatkezelés céljára továbbítottuk, kivéve akkor, ha az értesítés
                elmaradása az Ön jogos érdekét nem sérti.
              </p>

              <p>
                Amennyiben az Ön helyesbítés, zárolás vagy törlés iránti kérelmét nem teljesítjük, a kérelem
                kézhezvételét követő 28 napon belül írásban vagy &ndash; az Ön hozzájárulásával &ndash; elektronikus
                úton közöljünk elutasításunk indokait és tájékoztatjuk Önt a bírósági jogorvoslat, továbbá a Hatósághoz
                fordulás lehetőségéről.
              </p>

              <p>
                Amennyiben Ön tiltakozik a személyes adatai kezelése ellen, a tiltakozást a kérelem benyújtásától
                számított legrövidebb időn belül, 28 napon belül megvizsgáljuk, és a döntésünkről Önt írásban
                tájékoztatjuk. Amennyiben úgy döntöttünk, hogy az Ön tiltakozása megalapozott, abban az esetben az
                adatkezelést - beleértve a további adatfelvételt és adattovábbítást is &ndash; megszüntetjük, és az
                adatokat zároljuk, valamint a tiltakozásról, továbbá az annak alapján tett intézkedésekről értesítjük
                mindazokat, akik részére a tiltakozással érintett személyes adatot korábban továbbítottuk, és akik
                kötelesek intézkedni a tiltakozási jog érvényesítése érdekében.
              </p>

              <p>
                Abban az esetben megtagadjuk a kérés teljesítését, ha bizonyítjuk, hogy az adatkezelést olyan
                kényszerítő erejű jogos okok indokolják, amelyek elsőbbséget élveznek az Ön érdekeivel, jogaival és
                szabadságaival szemben, vagy amelyek jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez
                kapcsolódnak. Amennyiben Ön a döntésünkkel nem ért egyet, illetve ha elmulasztjuk a határidőt, a döntés
                közlésétől, illetve a határidő utolsó napjától számított 30 napon belül Ön bírósághoz fordulhat.
              </p>

              <p>
                Az adatvédelmi perek elbírálása a törvényszék hatáskörébe tartozik, a per &ndash; az érintett választása
                szerint &ndash; az érintett lakhelye vagy tartózkodási helye szerinti törvényszék előtt is megindítható.
                Külföldi állampolgár a lakóhelye szerint illetékes felügyeleti hatósághoz is fordulhat panasszal.
              </p>

              <p>
                Kérjük Önt, hogy mielőtt a felügyeleti hatósághoz vagy bírósághoz fordulna panaszával &ndash; egyeztetés
                és a felmerült probléma minél gyorsabb megoldása érdekében &ndash; keresse meg bennünket.
              </p>

              <p>A Szolgáltató köteles az adatkezeléssel kapcsolatos alábbi jogszabályok és ajánlások betartására:</p>

              <ul>
                <li>Magyarország Alaptörvénye, VI. cikk</li>
                <li>
                  a természetes személyeknek a személyes adatok kezeléséről szóló az Európai Parlament és a Tanács (EU)
                  2016/679 rendelete (GDPR)
                </li>
                <li>
                  2011. évi CXII. törvény az információs önrendelkezési jogról és az információszabadságról (Info tv.)
                </li>
                <li>a Polgári Törvénykönyvről szóló 2013. évi V. törvény (Ptk.)</li>
                <li>
                  a 2001. évi CVIII. törvény &ndash; Eker tv. (Az információs társadalommal összefüggő szolgáltatások
                  egyes kérdéseiről),
                </li>
                <li>az elektronikus hírközlésről szóló 2003. évi C. törvény - (Ehtv)</li>
                <li>a fogyasztóvédelemről szóló 1997. évi CLV. törvény (Fogyv tv.)</li>
                <li>a panaszokról és a közérdekű bejelentésekről szóló 2013. évi CLXV. törvény. (Pktv.)</li>
                <li>
                  a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII.
                  törvény (Grtv.)
                </li>
              </ul>

              <h3>Az adatvédelmi nyilatkozat módosításának lehetősége</h3>
              <p>
                Az adatkezelő fenntartja a jogot, hogy jelen adatvédelmi tájékoztatót a felhasználók előzetes értesítése
                mellett egyoldalúan módosítsa. A módosítás hatályba lépését követően a felhasználó a szolgáltatás
                használatával ráutaló magatartással elfogadja a módosított adatvédelmi nyilatkozatban foglaltakat. Az
                adatkezeléssel kapcsolatos információk közzététele a
                <a href="https://www.google.com/url?q=https://clickncruise.hu/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1654877844917980&amp;usg=AOvVaw1wNVpaiqEC5YObF4BYfXZl">
                  https://clickncruise.hu/privacy-policy
                </a>
                &nbsp; weboldalon történik.
              </p>

              <p>6. Az Ön jogai</p>
              <ul>
                <li>
                  Az adatkezeléssel kapcsolatos felvilágosításhoz és a feldolgozott adatok másolatának megszerzéséhez
                  való jog (hozzáférési jog, a GDPR 15. cikke),
                </li>
                <li>
                  a helytelen adatok helyesbítésének vagy a hiányos adatok kiegészítésének kérésére vonatkozó jog (a
                  helyesbítéshez való jog, a GDPR 16. cikke),
                </li>
                <li>
                  a személyes adatok törlésének kérésére vonatkozó jog, amennyiben fennállnak ennek törvényi feltételei,
                  valamint a személyes adatok átadása esetén az egyéb adatkezelőknek a törlés iránti kérelemmel
                  kapcsolatban elküldött információk, amennyiben ezt a jogszabály előírja (a törléshez való jog, a GDPR
                  17. cikke),
                </li>
                <li>
                  az adatkezelés korlátozásának kérésére vonatkozó jog (az adatkezelés korlátozásához való jog, a GDPR
                  18. cikke),
                </li>
                <li>
                  a személyes adatok strukturált, széles körben használt és géppel olvasható formátumban történő
                  megszerzésének joga, valamint ezen adatok más, az adatkezelésért felelős félnek való átadásának
                  kérésére vonatkozó jog (az adathordozhatósághoz való jog, a GDPR 20. cikke),
                </li>
                <li>
                  az adatkezelés elleni tiltakozásra vonatkozó jog az adatkezelés megakadályozása érdekében, amennyiben
                  az adatkezelés az Ön hozzájárulásán, ill. cégünk vagy valamely harmadik fél jogos érdekein alapul. Ez
                  a jog nem érvényes abban az esetben, ha az adatkezelést olyan kényszerítő okok indokolják, amelyek
                  elsőbbséget élveznek az Ön érdekeivel szemben, vagy ha az adataira jogi követelések érvényesítéséhez,
                  gyakorlásához vagy védelméhez van szükség (a tiltakozáshoz való jog, a GDPR 21. cikke),
                </li>
                <li>
                  a megosztott felelősség lényeges vonatkozásaival kapcsolatos információkhoz való jog, amelyekből
                  kiderül minden, a személyes adatok kezeléséért felelős személy szerepe és felelőssége, valamint az
                  érintettek jogainak gyakorlására szolgáló mechanizmusok és folyamatok (a GDPR 28. cikkének (2)
                  bekezdése);
                </li>
                <li>
                  a korábban adott hozzájárulás bármikor történő visszavonásának joga a hozzájáruláson alapuló
                  adatkezelés megakadályozása érdekében. A visszavonás nem befolyásolja a visszavonást megelőző,
                  hozzájáruláson alapuló adatkezelés jogszerűségét (a visszavonáshoz való jog, a GDPR 7. cikke),
                  valamint
                </li>
              </ul>
              <p>
                Önnek jogában áll a személyes adatai kezeléséhez és a GDPR szerinti jogainak gyakorlásához kapcsolódó
                valamennyi kérdésben adatvédelmi tisztviselőnkhöz fordulni (a GDPR 38. cikkének (4) bekezdése), ill. az
                illetékes felügyeleti hatósághoz panaszt benyújtani, ha úgy gondolja, hogy az adatkezelés sérti a GDPR-t
                (a GDPR 77. cikke).
              </p>
            </SectionBox>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
PrivacyPolicyPageHU.displayName = 'PrivacyPolicyPageHU';
