import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionBox } from '../../components/section-box/section-box';
import { SortPanel } from '../../components/sort-panel/sort-panel';
import { useTracking } from '../../hooks/useTracking';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';
import { CruiseSearchRequestSortDirectionEnum, CruiseSearchRequestSortEnum } from '../../services/search';
import { ReactComponent as FilterIcon } from './filter-icon.svg';
import './search-result-header.scss';

const popularFilterConfigs: Record<
  string,
  {
    isEnabled: (f: CruiseSearchFilters) => boolean;
    enable: (f: CruiseSearchFilters) => void;
    disable: (f: CruiseSearchFilters) => void;
  }
> = {
  'last-minute': {
    isEnabled: (f: CruiseSearchFilters) => {
      const maxDate = new Date();
      maxDate.setMonth(maxDate.getMonth() + 2);
      return !!f.when.from && !!f.when.to && f.when.to <= maxDate;
    },
    enable: (f: CruiseSearchFilters) => {
      f.when.from = new Date();
      f.when.to = new Date();
      f.when.to.setMonth(f.when.to.getMonth() + 2);
      f.promo = [];
    },
    disable: (f: CruiseSearchFilters) => {
      f.when.from = null;
      f.when.to = null;
    }
  },
  mediterranean: {
    isEnabled: (f: CruiseSearchFilters) => f.where.includes('Mediterranean'),
    enable: (f: CruiseSearchFilters) => (f.where = ['Mediterranean']),
    disable: (f: CruiseSearchFilters) => f.where.splice(f.where.indexOf('Mediterranean'), 1)
  },
  caribbean: {
    isEnabled: (f: CruiseSearchFilters) => f.where.includes('Caribbean'),
    enable: (f: CruiseSearchFilters) => (f.where = ['Caribbean']),
    disable: (f: CruiseSearchFilters) => f.where.splice(f.where.indexOf('Caribbean'), 1)
  },
  dubai: {
    isEnabled: (f: CruiseSearchFilters) => f.where.includes('Dubai & UAE'),
    enable: (f: CruiseSearchFilters) => (f.where = ['Dubai & UAE']),
    disable: (f: CruiseSearchFilters) => f.where.splice(f.where.indexOf('Dubai & UAE'), 1)
  },
  'sail-from-the-uk': {
    isEnabled: (f: CruiseSearchFilters) =>
      f.portsOfCall.includes('Southampton (London), United Kingdom') &&
      f.portsOfCall.includes('Dover (London), United Kingdom'),
    enable: (f: CruiseSearchFilters) =>
      (f.portsOfCall = ['Southampton (London), United Kingdom', 'Dover (London), United Kingdom']),
    disable: (f: CruiseSearchFilters) => {
      f.portsOfCall.splice(f.portsOfCall.indexOf('Southampton (London), United Kingdom'), 1);
      f.portsOfCall.splice(f.portsOfCall.indexOf('Dover (London), United Kingdom'), 1);
    }
  },
  'balcony-deals': {
    isEnabled: (f: CruiseSearchFilters) => f.cabinCategory.includes('balcony') && f.price.includes('0-500'),
    enable: (f: CruiseSearchFilters) => {
      f.cabinCategory = ['balcony'];
      f.price = ['0-500'];
    },
    disable: (f: CruiseSearchFilters) => {
      f.cabinCategory = [];
      f.price = [];
    }
  },
  'short-breaks': {
    isEnabled: (f: CruiseSearchFilters) => f.cruiseLength.includes('3-5'),
    enable: (f: CruiseSearchFilters) => (f.cruiseLength = ['3-5']),
    disable: (f: CruiseSearchFilters) => (f.cruiseLength = [])
  },
  'northern-europe': {
    isEnabled: (f: CruiseSearchFilters) => f.where.includes('Northern Europe'),
    enable: (f: CruiseSearchFilters) => (f.where = ['Northern Europe']),
    disable: (f: CruiseSearchFilters) => f.where.splice(f.where.indexOf('Northern Europe'), 1)
  },
  summer: {
    isEnabled: (f: CruiseSearchFilters) =>
      !!f.when.from && !!f.when.to && f.when.from <= new Date(2025, 6 - 1, 1) && f.when.to >= new Date(2025, 8 - 1, 30),
    enable: (f: CruiseSearchFilters) => {
      f.when.from = new Date(2025, 6 - 1, 1);
      f.when.to = new Date(2025, 8 - 1, 30);
    },
    disable: (f: CruiseSearchFilters) => {
      f.when.from = null;
      f.when.to = null;
    }
  },
  autumn: {
    isEnabled: (f: CruiseSearchFilters) =>
      !!f.when.from &&
      !!f.when.to &&
      f.when.from <= new Date(2025, 9 - 1, 30) &&
      f.when.to >= new Date(2025, 11 - 1, 30),
    enable: (f: CruiseSearchFilters) => {
      f.when.from = new Date(2025, 9 - 1, 1);
      f.when.to = new Date(2025, 11 - 1, 30);
    },
    disable: (f: CruiseSearchFilters) => {
      f.when.from = null;
      f.when.to = null;
    }
  },
  winter: {
    isEnabled: (f: CruiseSearchFilters) =>
      !!f.when.from &&
      !!f.when.to &&
      f.when.from <= new Date(2024, 12 - 1, 1) &&
      f.when.to >= new Date(2025, 2 - 1, 28),
    enable: (f: CruiseSearchFilters) => {
      f.when.from = new Date(2024, 12 - 1, 1);
      f.when.to = new Date(2025, 2 - 1, 28);
    },
    disable: (f: CruiseSearchFilters) => {
      f.when.from = null;
      f.when.to = null;
    }
  },
  week: {
    isEnabled: (f: CruiseSearchFilters) => f.cruiseLength.includes('6-8'),
    enable: (f: CruiseSearchFilters) => (f.cruiseLength = ['6-8']),
    disable: (f: CruiseSearchFilters) => (f.cruiseLength = [])
  },
  long: {
    isEnabled: (f: CruiseSearchFilters) => f.cruiseLength.includes('9-15') && f.cruiseLength.includes('15+'),
    enable: (f: CruiseSearchFilters) => (f.cruiseLength = ['9-15', '15+']),
    disable: (f: CruiseSearchFilters) => (f.cruiseLength = [])
  },
  view: {
    isEnabled: (f: CruiseSearchFilters) => f.cabinCategory.includes('balcony') && f.cabinCategory.includes('oceanview'),
    enable: (f: CruiseSearchFilters) => (f.cabinCategory = ['balcony', 'oceanview']),
    disable: (f: CruiseSearchFilters) => (f.cabinCategory = [])
  },
  kids: {
    isEnabled: (f: CruiseSearchFilters) => f.guests.child > 0,
    enable: (f: CruiseSearchFilters) => (f.guests.child += 1),
    disable: (f: CruiseSearchFilters) => (f.guests.child === 1 ? (f.guests.child = 2) : (f.guests.child = 0))
  },
  'adults-only': {
    isEnabled: (f: CruiseSearchFilters) => f.cruiseLine.length === 1 && f.cruiseLine.includes('VIRGIN'),
    enable: (f: CruiseSearchFilters) => (f.cruiseLine = ['VIRGIN']),
    disable: (f: CruiseSearchFilters) => f.cruiseLine.splice(f.cruiseLine.indexOf('VIRGIN'), 1)
  }
};

const popularFilters = __CONFIG__.quickSearch.search.map((name) => ({
  label: `search.filters.popularFilters.${name}`,
  ...popularFilterConfigs[name],
  name
}));

export const SearchResultHeader: React.FC<{
  searchFilters: CruiseSearchFilters;
  updateSearchFilters: (f: CruiseSearchFilters) => void;
  searchClicked: () => void;
  navigateToFilters: () => void;
  onSort: (sort: CruiseSearchRequestSortEnum, direction: CruiseSearchRequestSortDirectionEnum) => void;
  mobileHeaderScrollPosition: number;
  setMobileHeaderScrollPosition: (y: number) => void;
}> = (props) => {
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const { t } = useTranslation('common');
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [boxShadow, setBoxShadow] = useState('none');

  const setShadowOnMobile = useCallback(() => {
    if (!isLayoutSmall || !containerRef.current) return;
    if (window.scrollY > 100 || containerRef.current.getBoundingClientRect().top === containerRef.current.offsetTop) {
      setBoxShadow('0 10px 10px -10px #aeaeae');
    } else {
      setBoxShadow('none');
    }
  }, [isLayoutSmall]);

  useEffect(() => {
    window.addEventListener('scroll', setShadowOnMobile);
    return () => {
      window.removeEventListener('scroll', setShadowOnMobile);
    };
  }, [setShadowOnMobile]);

  useEffect(() => {
    if (isLayoutSmall && props.mobileHeaderScrollPosition !== 0 && scrollerRef.current) {
      scrollerRef.current.scroll({ left: props.mobileHeaderScrollPosition });
    }
  }, [isLayoutSmall, props.mobileHeaderScrollPosition]);

  return (
    <SectionBox className="search-result-title">
      <Grid container ref={containerRef} spacing={1} flexWrap="nowrap" sx={{ boxShadow }}>
        <Grid item className="scroll-horizontally" ref={scrollerRef}>
          <Grid container spacing={1} flexWrap={isLayoutSmall ? 'nowrap' : 'wrap'}>
            {popularFilters
              .filter((popularFilter) => isLayoutSmall || popularFilter.name !== 'kids')
              .map((popularFilter, index) => (
                <Grid item key={index}>
                  <Button
                    className={`popular-filter-button ${popularFilter.name}`}
                    color="secondary"
                    variant={popularFilter.isEnabled(props.searchFilters) ? 'contained' : 'outlined'}
                    fullWidth
                    onClick={() => {
                      trackEvent('popular_filter', { label: popularFilter.label, name: popularFilter.name });
                      if (scrollerRef.current) {
                        props.setMobileHeaderScrollPosition(scrollerRef.current.scrollLeft);
                      }
                      if (popularFilter.isEnabled(props.searchFilters)) {
                        popularFilter.disable(props.searchFilters);
                      } else {
                        popularFilter.enable(props.searchFilters);
                      }
                      props.searchClicked();
                      props.updateSearchFilters(props.searchFilters);
                    }}
                  >
                    {t(popularFilter.label, {
                      childCount: props.searchFilters.guests.child ? `: ${props.searchFilters.guests.child}` : ''
                    })}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Grid>
        {isLayoutSmall && (
          <Grid item>
            <Button
              onClick={() => {
                trackEvent('filter_open');
                props.navigateToFilters();
              }}
              sx={{ padding: '10px 0', minWidth: '42px' }}
              color="primary"
              variant="contained"
            >
              <FilterIcon />
            </Button>
          </Grid>
        )}
        <Grid item sx={{ marginLeft: 'auto' }}>
          <SortPanel onSort={props.onSort} searchFilters={props.searchFilters} />
        </Grid>
      </Grid>
    </SectionBox>
  );
};
