import { Box, Link, Stack } from '@mui/material';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';

export const PrivacyPolicyPageAE: React.FC<{}> = (props) => {
  return (
    <>
      <Box>
        <Stack spacing={8}>
          <Box padding={{ xs: '0 20px', md: '0 100px' }}>
            <SectionTitle title={'Privacy Policy'} />
            <SectionBox boxParams={{ style: { marginTop: '40px' } }}>
              <p>Our Privacy Policy document is getting officially translated, meanwhile please refer to:</p>
              <Link
                href="https://clickncruise-hu.translate.goog/privacy-policy?_x_tr_sl=hu&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp"
                target="_blank"
              >
                Open Privacy Policy
              </Link>
              <p></p>
            </SectionBox>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
PrivacyPolicyPageAE.displayName = 'PrivacyPolicyPageAE';
