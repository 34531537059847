import { Box, Link, Stack } from '@mui/material';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';

export const TermsAndConditionsPageAE: React.FC<{}> = (props) => {
  return (
    <>
      <Box>
        <Stack spacing={8}>
          <Box padding={{ xs: '0 20px', md: '0 100px' }}>
            <SectionTitle title={'Terms & Conditions'} />
            <SectionBox boxParams={{ style: { marginTop: '40px' } }}>
              <p>Our Terms & Conditions document is getting officially translated, meanwhile please refer to:</p>
              <Link
                href="https://clickncruise-hu.translate.goog/terms-and-conditions?_x_tr_sl=hu&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapps"
                target="_blank"
              >
                Open T&C
              </Link>
              <p></p>
            </SectionBox>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
TermsAndConditionsPageAE.displayName = 'TermsAndConditionsPageAE';
