import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { Trans, useTranslation } from 'react-i18next';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useTracking } from '../../hooks/useTracking';
import { BookingCruiseCabin, BookingState, IBookingGuestState } from '../../models/BookingState';
import { BookingDepartureCallbackDialog } from '../booking-departure-callback-dialog/booking-departure-callback-dialog';
import { ColorButton } from '../color-button/color-button';
import { Price, PriceType } from '../price/price';
import './booking-cabin-type-item.scss';
import { AvailabilityLabel } from '../labels/availability-label';

export const BookingCabinTypeItem: React.FC<{
  data: BookingCruiseCabin;
  bookingState: BookingState;
  handleChange: (cruiseCode: string, g?: IBookingGuestState) => void;
  afterCallbackSubmit: () => void;
  selected?: boolean;
}> = (props) => {
  const { trackEvent } = useTracking();
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const availabilityLabel =
    props.data.cruiseLine === 'MSC' ? (
      <AvailabilityLabel availableCount={props.data.availableCount} refreshTime={props.data.refreshTime} />
    ) : (
      <></>
    );

  return (
    <>
      <Stack
        className={`booking-cabin-type-item ${props.selected ? 'selected' : ''}`}
        direction={isLayoutSmall ? 'column' : 'row'}
        padding={isLayoutSmall ? 0 : 2}
        alignItems={isLayoutSmall ? 'flex-end' : 'center'}
      >
        <Box>
          <Zoom zoomMargin={isLayoutSmall ? 0 : 50} wrapStyle={{ width: '100%' }}>
            <img
              src={`${props.data.imageUrl}&w=960`}
              alt=""
              width={isLayoutSmall ? '100%' : 255}
              style={{ borderRadius: '5px' }}
            />
          </Zoom>
        </Box>

        <Stack
          margin={{ xs: '-8px 0 16px 0', sm: '0 0 0 8px' }}
          width={isLayoutSmall ? 'auto' : 'calc(100% - 255px - 240px - 15px)'}
          maxWidth={isLayoutSmall ? '320px' : 'auto'}
          style={{
            borderWidth: isLayoutSmall ? '0 1px 1px 1px' : '0',
            borderColor: '#E5E5E5',
            borderStyle: 'solid',
            borderRadius: '0 0 5px 5px',
            padding: isLayoutSmall ? '13px 6px 6px 6px' : '0'
          }}
        >
          {props.data.description && (
            <Typography variant="body2" style={{ textAlign: isLayoutSmall ? 'center' : 'left' }}>
              {props.data.description}
            </Typography>
          )}

          {!isLayoutSmall && availabilityLabel}
        </Stack>

        <Stack
          justifyContent="space-between"
          alignItems="flex-end"
          padding={0}
          spacing={0.5}
          sx={{
            fontSize: '12px',
            margin: { xs: 0, md: '0 0 10px 0', minWidth: '240px', width: isLayoutSmall ? '100%' : '260px' }
          }}
        >
          <Grid container justifyContent="space-between" alignItems="flex-start">
            {isLayoutSmall && <Grid item>{availabilityLabel}</Grid>}
            <Grid item style={{ marginLeft: 'auto' }}>
              <Price
                mainPrice={new PriceType(props.data.pricePerPerson)}
                localPrice={
                  new PriceType(props.data.pricePerPersonLocalCcy, __CONFIG__.currency.localCurrency, null, 'estimate')
                }
              />
            </Grid>
          </Grid>

          <Typography variant="body2" textAlign="right" style={{ overflowWrap: 'anywhere' }}>
            <Trans t={t} i18nKey={`booking.departure.mandatory-service-fees`}></Trans>
          </Typography>

          <Stack>
            <ColorButton
              label={t('booking.cabintype.choose-button-label')}
              data-cabin-id={props.data.id}
              onClick={(e) => {
                trackEvent('cabin_select', { category: props.data.categoryGroup });
                props.handleChange(props.data.id);
              }}
              fullWidth
            />
            {__CONFIG__.booking.callbackEnabled && (
              <BookingDepartureCallbackDialog
                data={props.bookingState}
                cabinId={props.data.id}
                handleChange={props.afterCallbackSubmit}
                color={'#020C25'}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

BookingCabinTypeItem.defaultProps = {
  selected: false
};
