import { Alert, Box, Checkbox, FormControlLabel, Grid, Link, LinkProps, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import countries from 'react-select-country-list';

import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { BookingGuestDetailsForm } from '../../components/booking-guest-details-form/booking-guest-details-form';
import { BookingOfferSummary } from '../../components/booking-offer-summary/booking-offer-summary';
import { ColorButton } from '../../components/color-button/color-button';
import { InputSelect, InputText } from '../../components/input-text/input-text';
import { BookingFormProps } from './BookingFormProps';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTracking } from '../../hooks/useTracking';
import { BookingGuestState, CheckoutStatus, GuestType } from '../../models/BookingState';
import { useCheckoutApi } from '../../contexts/checkout-api.context';

export const BookingGuestsPage: React.FC<BookingFormProps> = (props: BookingFormProps) => {
  const { trackEvent, hashContactInfo } = useTracking();
  const { checkoutApi } = useCheckoutApi();

  // Theme
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isLayoutMedium = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  // Translation
  const { t } = useTranslation('common');

  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const linkProps: LinkProps = { target: '_blank', rel: 'noreferrer', sx: { fontWeight: '600', color: '#3E3E3E' } };

  const legalCheckboxesList = [
    {
      name: 'termsAndConditions',
      component: <Link {...linkProps} component={RouterLink} to="/terms-and-conditions" />
    },
    {
      name: 'cruiselineTerms',
      component: (
        <Link
          {...linkProps}
          href={t(
            `booking.guests.cruiseline-term-docs.${
              props.bookingState.bookingDetails ? props.bookingState.bookingDetails.cruiseLine : ''
            }`
          )}
        />
      )
    },
    { name: 'privacyPolicy', component: <Link {...linkProps} component={RouterLink} to="/privacy-policy" /> },
    { name: 'newsLetter', component: <strong /> }
  ];

  if (__CONFIG__.booking.form.cubaCheckboxEnabled) {
    legalCheckboxesList.splice(legalCheckboxesList.length - 1, 0, { name: 'travelledToCuba', component: <Box /> });
  }

  const autoBooking = props.bookingState.bookingDetails?.cruiseLine === 'MSC';

  const countryList = (countries().native() as any).nativeData.sort((a: any, b: any) => {
    if (a.value === __CONFIG__.booking.form.defaultInvoiceCountry) {
      return -1;
    }
    if (b.value === __CONFIG__.booking.form.defaultInvoiceCountry) {
      return 1;
    }
    return a.label.localeCompare(b.label);
  });

  // Form
  const methods = useForm<any>();
  const { handleSubmit, formState, control, setValue } = methods;

  const trackBookingEvent = async () => {
    trackEvent('book', {
      booking_id: props.bookingState.bookingId || '',
      conversion: { revenue: props.bookingState.calculateTotalPrice(true), currency: __CONFIG__.currency.default },
      cruise_group_id: props.bookingState.bookingDetails?.cruiseGroupId,
      currency: __CONFIG__.currency.default,
      price: props.bookingState.calculateTotalPrice(true),
      ...(await hashContactInfo(
        props.bookingState.guestsState.guestList?.[0].email,
        props.bookingState.guestsState.guestList?.[0].phone
      ))
    });
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    // Save all fields again because autofill does not trigger blur and hence booking state is stale
    for (const fieldId in data) {
      const fieldIdParts = fieldId.split('-');
      const fieldName = fieldIdParts[0];
      const fieldValue = data[fieldId];
      if (fieldName === 'notes') {
        props.bookingState.guestsState.notes = fieldValue;
      } else if (fieldName === 'invoice') {
        (props.bookingState.guestsState.invoice as any)[fieldIdParts[1]] = fieldValue;
      } else if (fieldIdParts.length > 2 && fieldIdParts[fieldIdParts.length - 2] === 'guest') {
        const guestIndex = parseInt(fieldIdParts[fieldIdParts.length - 1]);
        (props.bookingState.guestsState.guestList[guestIndex] as any)[fieldName] = fieldValue;
      }
    }

    props.bookingState.source = 'book';

    props.updateBooking(props.bookingState);

    setIsLoading(true);
    await trackBookingEvent();

    legalCheckboxesList.forEach(
      (l) => ((props.bookingState.guestsState.legalChecks as any)[l.name] = data[`legal-${l.name}`])
    );
    props.bookingState.guestsState.legalChecks.allChecked = data['legal-allChecked'];

    props.bookingState.completedSteps.push(3);
    props.updateBooking(props.bookingState);

    // SAVE BOOKING DATA
    checkoutApi
      .updateBooking(props.bookingState.toUpdateBookingPayload(CheckoutStatus.GUESTS_PROVIDED))
      .then((response: any) => {
        props.bookingState.guestsState.stripeCustomerId = response.data.stripeCustomerId;
        props.updateBooking(props.bookingState);

        setIsLoading(false);
        props.onSubmit();
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const requiredTextFieldValidator = {
    required: `${t('form.required-helper-text')}`,
    pattern: {
      value: /([a-zA-Z]+)/,
      message: t('form.required-helper-text')
    }
  };

  // Methods
  const hasFormError = (type: string) => (): boolean => {
    for (let e in formState.errors) {
      if (e.startsWith(type)) {
        return true;
      }
    }
    return false;
  };

  // Change handlers
  const handleInvoiceFieldChange = (event: any) => {
    const fieldId = event.target.id || event.target.name;
    (props.bookingState.guestsState.invoice as any)[fieldId.split('-')[1]] = event.target.value?.trim();

    props.updateBooking(props.bookingState);
  };

  const handleNotesFieldChange = (event: any) => {
    props.bookingState.guestsState.notes = event.target.value?.trim();
    props.updateBooking(props.bookingState);
  };

  const updateGuestState = (type: GuestType, guestIndex: number) => (g: BookingGuestState) => {
    props.bookingState.guestsState.guestList[guestIndex] = g;
    props.updateBooking(props.bookingState);
  };

  // Components
  const Title = () => {
    if (!isLayoutMedium) {
      return (
        <>
          <Typography variant="h2" textAlign="center" marginBottom="8px">
            {t('booking.guests.sub-title')}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="h3" textAlign="left" marginBottom="8px">
            {t('booking.guests.sub-title')}
          </Typography>
        </>
      );
    }
  };

  const holdingOnly = __CONFIG__.booking.holdingEnabled && props.bookingState.shouldPayDeposit();
  const isMSCPromo = props.bookingState.isMSCPromo();
  const zipCodeEnabled = __CONFIG__.booking.form.zipCodeEnabled;

  return (
    <>
      <Box>
        <Stack direction={isLayoutSmall ? 'column' : 'row'} spacing={3}>
          <Box width={isLayoutSmall ? '100%' : '30%'}>
            <BookingOfferSummary
              bookingState={props.bookingState}
              updateBooking={props.updateBooking}
              readonly={true}
              discountsEnabled={true}
            />
          </Box>
          <Stack width={isLayoutSmall ? '100%' : '70%'} style={{ marginTop: isLayoutSmall ? 0 : undefined }}>
            <Title />

            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Guest forms */}

                {props.bookingState.guestsState.guestList
                  .sort((a, b) => a.type.localeCompare(b.type))
                  .map((g, i) => (
                    <Box key={i}>
                      <BookingGuestDetailsForm
                        guestIndex={i}
                        bookingState={props.bookingState}
                        guestState={g}
                        updateGuestState={updateGuestState(g.type, i)}
                      />
                    </Box>
                  ))}

                <Grid container columnSpacing={3} rowSpacing={1} marginBottom={3}>
                  {/* Invoice */}

                  <Grid item xs={12}>
                    <Typography
                      className={`form-group-header ${hasFormError('invoice')() ? 'error' : ''}`}
                      variant="h3"
                      textAlign="left"
                      marginBottom="0px"
                    >
                      {t('booking.guests.form.guest-data-invoice-form-header')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputSelect
                      id={`invoice-country-input`}
                      label="booking.guests.form.country.label"
                      placeholder="booking.guests.form.country.placeholder"
                      onInputBlur={handleInvoiceFieldChange}
                      validationRules={requiredTextFieldValidator}
                      defaultValue={
                        props.bookingState.guestsState.invoice.country || __CONFIG__.booking.form.defaultInvoiceCountry
                      }
                      options={countryList}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputText
                      id={`invoice-city-input`}
                      label="booking.guests.form.city.label"
                      placeholder="booking.guests.form.city.placeholder"
                      onInputBlur={handleInvoiceFieldChange}
                      validationRules={requiredTextFieldValidator}
                      defaultValue={props.bookingState.guestsState.invoice.city}
                    />
                  </Grid>

                  <Grid item xs={12} md={zipCodeEnabled ? 9 : 12}>
                    <InputText
                      id={`invoice-address-input`}
                      label="booking.guests.form.address.label"
                      placeholder="booking.guests.form.address.placeholder"
                      onInputBlur={handleInvoiceFieldChange}
                      validationRules={requiredTextFieldValidator}
                      defaultValue={props.bookingState.guestsState.invoice.address}
                    />
                  </Grid>

                  {zipCodeEnabled && (
                    <Grid item xs={5} md={3}>
                      <InputText
                        id={`invoice-zipCode-input`}
                        label="booking.guests.form.zip-code.label"
                        placeholder="booking.guests.form.zip-code.placeholder"
                        onInputBlur={handleInvoiceFieldChange}
                        validationRules={{
                          required: `${t('form.required-helper-text')}`,
                          pattern: {
                            value: /([a-zA-Z0-9]+)/,
                            message: t('form.required-helper-text')
                          }
                        }}
                        defaultValue={props.bookingState.guestsState.invoice.zipCode}
                      />
                    </Grid>
                  )}

                  {/* Notes */}

                  <Grid item xs={12}>
                    <Typography
                      className={`form-group-header ${hasFormError('notes')() ? 'error' : ''}`}
                      variant="h3"
                      textAlign="left"
                      margin="16px 0 0 0"
                    >
                      {t('booking.guests.form.guest-data-notes-header')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <InputText
                      id={`notes-input`}
                      placeholder="booking.guests.form.notes.placeholder"
                      onInputBlur={handleNotesFieldChange}
                      defaultValue={props.bookingState.guestsState.notes}
                      multiline
                      minRows={2}
                      maxRows={4}
                    />
                  </Grid>
                </Grid>

                {/* Terms and legal checkboxes */}

                <Stack>
                  <Typography
                    className={`form-group-header ${hasFormError('legal')() ? 'error' : ''}`}
                    variant="h3"
                    textAlign="left"
                    marginBottom={2}
                  >
                    {t('booking.guests.form.guest-data-terms-form-header')}
                  </Typography>

                  <Controller
                    name={`legal-allChecked`}
                    control={control}
                    defaultValue={props.bookingState.guestsState.legalChecks.allChecked}
                    render={({ field }) => {
                      const onChange = (e: any) => {
                        field.onChange(e.target.checked);
                        legalCheckboxesList.forEach((l) =>
                          setValue(`legal-${l.name}`, e.target.checked, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true
                          })
                        );
                      };

                      return (
                        <FormControlLabel
                          label={
                            <Trans
                              t={t}
                              i18nKey={`booking.guests.form.terms.accept-all`}
                              components={{ strong: <strong /> }}
                            />
                          }
                          control={<Checkbox onChange={onChange} checked={field.value ?? false} />}
                        />
                      );
                    }}
                  />

                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                    {legalCheckboxesList.map((chkObj, i) => (
                      <Stack direction="row" alignItems="center" spacing={1} key={i}>
                        {formState.errors[`legal-${chkObj.name}`] && <ErrorOutlineIcon style={{ color: '#FF1257' }} />}
                        <Controller
                          name={`legal-${chkObj.name}`}
                          control={control}
                          rules={{ required: chkObj.name !== 'newsLetter' }}
                          defaultValue={(props.bookingState.guestsState.legalChecks as any)[chkObj.name]}
                          render={({ field }) => (
                            <FormControlLabel
                              label={
                                <Trans
                                  t={t}
                                  i18nKey={`booking.guests.form.terms.${chkObj.name}`}
                                  components={{ strong: chkObj.component }}
                                />
                              }
                              control={
                                <Checkbox
                                  onChange={(e) => field.onChange(e.target.checked)}
                                  checked={field.value ?? false}
                                />
                              }
                            />
                          )}
                        />
                      </Stack>
                    ))}
                  </Box>
                  {isMSCPromo && (
                    <Alert severity="info" variant="outlined">
                      {t('booking.guests.disclaimer.msc-promo')}
                    </Alert>
                  )}
                </Stack>
                <Box textAlign={isLayoutSmall ? 'center' : 'left'}>
                  <ColorButton
                    label={
                      autoBooking ? t('booking.guests.book-button-label') : t('booking.guests.get-quote-button-label')
                    }
                    type="submit"
                    style={{ marginTop: '30px', width: isLayoutSmall ? '80%' : '' }}
                    disabled={isLoading}
                  />
                </Box>

                <Box marginTop={3}>
                  {autoBooking && (
                    <ul style={{ marginLeft: -16 }}>
                      <li>{t('booking.guests.disclaimer.payment-redirect')}</li>
                      <li>{t('booking.guests.disclaimer.payment-secure')}</li>
                    </ul>
                  )}
                  {!autoBooking && !holdingOnly && (
                    <ul style={{ marginLeft: -16 }}>
                      <li>{t('booking.guests.disclaimer.no-holding')}</li>
                    </ul>
                  )}
                  {!autoBooking && holdingOnly && (
                    <ul style={{ marginLeft: -16 }}>
                      <li>{t('booking.guests.disclaimer.quote')}</li>
                      <li>{t('booking.guests.disclaimer.quote-contact')}</li>
                    </ul>
                  )}
                </Box>
              </form>
            </FormProvider>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
