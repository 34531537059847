export interface GuestFilterOption {
  readonly value: string;
  readonly label: string;
  readonly hint: string;
  count: number;
  maxCount: number;
}

export class GuestFilterOptions {
  adult: GuestFilterOption = {
    label: 'search.filters.guest.values.adult.label',
    value: 'adult',
    hint: 'search.filters.guest.values.adult.hint',
    count: 0,
    maxCount: 4
  };
  child: GuestFilterOption = {
    label: 'search.filters.guest.values.child.label',
    value: 'child',
    hint: 'search.filters.guest.values.child.hint',
    count: 0,
    maxCount: 3
  };

  get(label: string): GuestFilterOption {
    return label === 'adult' ? this.adult : this.child;
  }

  getAll(): GuestFilterOption[] {
    return [this.adult, this.child];
  }
}

export class FilterOptions {
  static guests = new GuestFilterOptions();

  static where = [
    { value: 'Mediterranean' },
    { value: 'Northern Europe' },
    { value: 'Caribbean' },
    { value: 'Dubai & UAE' },
    { value: 'Africa' },
    { value: 'Alaska' },
    { value: 'Arctic and Greenland' },
    { value: 'Asia' },
    { value: 'Australia & Oceania' },
    { value: 'Canaries' },
    { value: 'North America' },
    { value: 'Panama Canal & Central America' },
    { value: 'South America & Antarctica' },
    { value: 'Transoceanic' },
    { value: 'World Cruise' }
  ];

  static cruiseLine = __CONFIG__.search.filters.cruiselines;
  static ships = __CONFIG__.search.filters.ships;

  static cabinCategory = [
    { value: 'inside' },
    { value: 'solo_studio' },
    { value: 'oceanview' },
    { value: 'balcony' },
    { value: 'suite' },
    { value: 'msc_yacht_club' }
  ];

  static priceRange = [
    { value: '0-500' },
    { value: '500-1000' },
    { value: '1000-1500' },
    { value: '1500-2000' },
    { value: '2000+' }
  ];

  static cruiseLength = [{ value: '1-2' }, { value: '3-5' }, { value: '6-8' }, { value: '9-15' }, { value: '15+' }];

  static portsOfCall = __CONFIG__.search.filters.portsOfCall;

  static intermediatePortsOfCall = __CONFIG__.search.filters.portsOfCall
    .concat(__CONFIG__.search.filters.extraIntermediatePortsOfCall)
    .sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });

  static routeOptions = [{ value: 'roundtrip' }];

  static seaDays = [{ value: '0' }, { value: '1-2' }, { value: '3-5' }, { value: '5+' }];

  static sustainability = [{ value: 'LNG' }, { value: 'Hybrid' }];

  static promo = [
    { value: '96 Hours' },
    { value: 'Black Friday' },
    { value: 'Last Minute' },
    { value: 'Winter Wonders' }
  ];

  static popularSearches = [
    { label: 'Summer 2022', value: 'Summer 2022' },
    { label: 'Family Cruises', value: 'Family Cruises' },
    { label: 'Mediterranean', value: 'Mediterranean' },
    { label: 'Caribbean', value: 'Caribbean' },
    { label: 'LNG Powered Ships', value: 'LNG Powered Ships' },
    { label: 'Christmas 2022', value: 'Christmas 2022' }
  ];
}
