import IosShareIcon from '@mui/icons-material/IosShare';
import ShareIcon from '@mui/icons-material/Share';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ReactElement, useState } from 'react';
import { isChrome, isEdge, isIOS, isMobile, isSafari } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTracking } from '../../hooks/useTracking';
import { CruiseDepartureDate, CruiseDetails } from '../../services/search';
import { CruiseItinerary } from '../cruise-itinerary/cruise-itinerary';
import { AdultsOnlyLabel } from '../labels/adults-only-label';
import { AvailabilityLabel } from '../labels/availability-label';
import { PromoLabel } from '../labels/promo-label';
import { Price, PriceType } from '../price/price';
import './cruise-details-panel.scss';

export const CruiseDetailsPanel: React.FC<{
  data: CruiseDetails;
  view: 'normal' | 'detail';
  guestsNumber?: number;
  showPrice?: boolean;
  showButton?: boolean;
}> = (props) => {
  const { trackEvent } = useTracking();
  const { t, i18n } = useTranslation('common');

  const firstDepartureDate = props.data.minDepartureDate ? new Date(props.data.minDepartureDate) : null;
  const departureDates = (props.data.departureDates || []).sort((a, b) => {
    if (a.fromDate === props.data.minDepartureDate) {
      return -1;
    } else if (b.fromDate === props.data.minDepartureDate) {
      return 1;
    } else {
      return 0;
    }
  });
  const onlyOneDepartureDate = departureDates.length <= 1;
  const [currentSelectedDeparture, setCurrentSelectedDeparture] = useState<CruiseDepartureDate>(departureDates[0]);

  const [mainPrice, setMainPrice] = useState<PriceType>(new PriceType(props.data.pricePerPerson));
  const [localPrice, setLocalPrice] = useState<PriceType>(
    new PriceType(props.data.priceLocalCcy, __CONFIG__.currency.localCurrency, null, 'estimate')
  );

  const departure = props.data.portsOfCall[0];
  const arrival = props.data.portsOfCall[props.data.portsOfCall.length - 1];

  const [bookPagePath, setBookPagePath] = useState<string>(
    `/book/${props.data.cruiseLine}/${props.data.cruiseGroupId}/departure`
  );

  const onDateScroll = (target: HTMLDivElement) => {
    if (target.scrollLeft > 0) {
      if (!target.className.includes('maskLeft')) {
        target.className += ' maskLeft';
      }
    } else if (target.className.includes('maskLeft')) {
      target.className = target.className.replace(' maskLeft', '');
    }

    if (target.scrollLeft >= target.scrollWidth - target.clientWidth) {
      if (!target.className.includes('noMaskRight')) {
        target.className += ' noMaskRight';
      }
    } else if (target.className.includes('noMaskRight')) {
      target.className = target.className.replace(' noMaskRight', '');
    }
  };

  const onDateClick = (dep: CruiseDepartureDate) => {
    setCurrentSelectedDeparture(dep);
    setBookPagePath(`/book/${props.data.cruiseLine}/${props.data.cruiseGroupId}/departure/${dep.id}`);
    setMainPrice(new PriceType(dep.pricePerPerson));
    setLocalPrice(new PriceType(dep.pricePerPersonLocalCcy, __CONFIG__.currency.localCurrency, null, 'estimate'));
    trackEvent('cruise_date_click', { cruise_group_id: props.data.cruiseGroupId });
    trackCruiseSelectEvent('date');
  };

  const shareClick = () => {
    const shareData = {
      url: `${window.location.origin}${bookPagePath}`,
      text: t(`home.header-title`),
      title: 'CLICKnCRUISE'
    };

    if (navigator.canShare(shareData)) {
      navigator.share(shareData).catch((e) => {});
      trackEvent('cruise_share', { cruise_group_id: props.data.cruiseGroupId });
    }
  };

  const trackCruiseSelectEvent = (source?: string) => {
    trackEvent('cruise_select', { cruise_group_id: props.data.cruiseGroupId, source });
  };

  const labels: ReactElement[] = [];
  if (props.view === 'normal' && props.data.cruiseLine === 'VIRGIN') {
    labels.push(<AdultsOnlyLabel />);
  }

  if (props.view === 'normal' && props.data.promo) {
    labels.push(<PromoLabel promo={props.data.promo} />);
  }

  if (props.view === 'normal' && props.data.cruiseLine === 'MSC') {
    labels.push(
      <AvailabilityLabel
        availableCount={currentSelectedDeparture.availableCount}
        refreshTime={currentSelectedDeparture.refreshTime}
      />
    );
  }

  return (
    <>
      <Box className="cruise-details-panel">
        <Stack className="company-info" direction="row" justifyContent="space-between" alignItems="center">
          <Box className="cruise-line-name">
            <Typography variant="h3">
              <Trans
                t={t}
                i18nKey="labels.cruise-length"
                values={{
                  count: props.data.cruiseLength
                }}
              />{' '}
              {t(`search.filters.where.options.${props.data.destinationRegion}`)},{' '}
              {t(`search.filters.portsOfCall.options.${departure}`, departure)}
              {departure !== arrival && <> - {t(`search.filters.portsOfCall.options.${arrival}`, arrival)}</>}
            </Typography>
          </Box>
          <Box>
            {/* @ts-ignore */}
            {(isMobile || isSafari || isChrome || isEdge) && navigator.share && (
              <IconButton color="primary" aria-label={t('labels.share-cruise')} component="span" onClick={shareClick}>
                {(isIOS || isSafari) && <IosShareIcon />}
                {!isIOS && !isSafari && <ShareIcon sx={{ color: '#757575' }} />}
              </IconButton>
            )}
          </Box>
        </Stack>

        {props.view === 'normal' && firstDepartureDate && (
          <Grid
            container
            mb={2}
            className="scroll-horizontally"
            onScroll={(e) => onDateScroll(e.target as HTMLDivElement)}
          >
            {departureDates.map((dep) => (
              <Grid item key={dep.id}>
                <Chip
                  label={t('booking.departure.first-date-option', {
                    date: new Date(dep.fromDate).toLocaleDateString(i18n.language, {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    })
                  })}
                  variant={currentSelectedDeparture === dep ? 'filled' : 'outlined'}
                  style={{
                    border: currentSelectedDeparture === dep ? '1px solid #0000' : undefined
                  }}
                  onClick={onlyOneDepartureDate ? undefined : () => onDateClick(dep)}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {props.view === 'normal' && (
          <Box>
            <CruiseItinerary
              portsOfCall={props.data.portsOfCall}
              cruiseLength={props.data.cruiseLength}
              seaDays={props.data.seaDays}
              mode="long"
            />
          </Box>
        )}

        <Grid container flexWrap="nowrap" alignItems="flex-end" mt={1} gap={1}>
          <Grid item flexShrink={1} sx={{ minWidth: 0, marginBottom: '6px' }}>
            <Grid container alignItems="flex-end" columnGap={1} rowGap={1.5}>
              {labels.map((label, i) => (
                <Grid item key={i}>
                  {label}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item flexShrink={0} style={{ marginLeft: 'auto' }}>
            <Stack spacing={1}>
              {props.view === 'normal' && props.showPrice && (
                <Box>
                  <Price mainPrice={mainPrice} localPrice={localPrice} />
                </Box>
              )}

              {props.view === 'normal' && props.showButton && (
                <Box textAlign="right">
                  <Link className="link-button primary" to={bookPagePath} onClick={() => trackCruiseSelectEvent()}>
                    {t('search.result-list.cruise.book')}
                  </Link>
                </Box>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

CruiseDetailsPanel.defaultProps = {
  view: 'normal',
  showPrice: true,
  showButton: true
};
